<template>
  <base-layout-two :page-title="`${user.fullname}`" :page-default-back-link="`/users/${$route.params.id}`"
    content-class="background-white">
    <section class="padding-x-16">
      <h1>Activity Timeline</h1>

      <ion-button class="regular-button" expand="block"
        :router-link="`/users/${$route.params.id}/activities/select`">New
        Activity</ion-button>
    </section>
    <ion-list class="padding-x-16">
      <activity-list-item v-for="activity in filteredActivities" :key="activity.id" :activity="activity"
        :router-link="`/users/${$route.params.id}/activities/${activity.id}`">
      </activity-list-item>
    </ion-list>
  </base-layout-two>
</template>

<script>
import { IonList, IonButton } from "@ionic/vue";
import ActivityListItem from "@/components/users/activities/ActivityListItem.vue";

export default {
  components: {
    IonList,
    IonButton,
    ActivityListItem,
  },

  data() {
    return {
      user: {},
      activities: [],
    };
  },

  computed: {
    filteredActivities() {
      let salesTypes = ["sales_info_session_zoom", "sales_ca_call_zoom", "sales_tour_onsite", "sales_followup", "sales_reservation"]

      // Sales Activities
      if (this.$route.query.type === 'sales')
        return this.activities.filter((x) => { return (salesTypes.includes(x.type)) ? true : false })

      // Care Activities
      else if (this.$route.query.type === 'care')
        return this.activities.filter((x) => { return (salesTypes.includes(x.type)) ? false : true })

      else return this.activities
    }
  },

  ionViewWillEnter() {
    this.fetchUser();
    this.fetchActivities();
  },

  methods: {
    async fetchUser() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/users/${this.$route.params.id}`)
        .then((response) => {
          this.user = response.data.user;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchActivities() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/activities/?user_id=${this.$route.params.id}`)
        .then((response) => {
          this.activities = response.data.activities.sort(function (a, b) { return new Date(b.datetime) - new Date(a.datetime) });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>