<template>
  <ion-item :lines="lines" class="ion-no-padding">
    <ion-icon slot="start" :src="activityIcon"></ion-icon>
    <ion-label class="ion-text-wrap">
      <div class="h6">
        {{ activityTitle }}
        <span class="ion-text-capitalize">
          {{ activity.measurement_1 ? `(${activity.measurement_1}${activity.units_1} ${activity.option_1})` : `` }}
        </span>

        <span class="danger" v-if="!activity.measurement_1 && showMeasurement1">
          (missing amount)
        </span>
      </div>
      <div v-if="activity.created_by">{{ (activity.scanned) ? 'Scanned by' : '' }} {{ activity.created_by.fullname }}
      </div>
      <div>{{ formatTimeAndDate(activity.datetime) }}</div>

      <div v-if="activity.notes" class="padding-top-8" style="white-space: pre-line">
        {{ activity.notes }}
      </div>
    </ion-label>
  </ion-item>
</template>

<script>
import { IonItem, IonLabel, IonIcon } from "@ionic/vue";
import { formatTimeAndDate } from "@/util/helpers";

export default {
  props: {
    activity: {
      type: Object,
      required: true,
    },
    lines: {
      type: String,
      default: "full",
    },
  },

  components: {
    IonItem,
    IonLabel,
    IonIcon,
  },

  computed: {
    activityIcon() {
      if (this.activity.type === "note")
        return "/assets/note.svg";
      if (this.activity.type === "cc_note")
        return "/assets/cc_note.svg";
      else if (this.activity.type === "sitz_bath")
        return "/assets/sitz_bath.svg";
      else if (this.activity.type === "foot_bath")
        return "/assets/foot_bath.svg";
      else if (this.activity.type === "breast_massage")
        return "/assets/breast_massage.svg";
      else if (this.activity.type === "lactation_assistance")
        return "/assets/lactation_assistance.svg";
      else if (this.activity.type === "pump_delivered")
        return "/assets/breast_pump_deliver.svg";
      else if (this.activity.type === "pump_retrieved")
        return "/assets/breast_pump_retrieve.svg";
      else if (this.activity.type === "baby_in_guest_room")
        return "/assets/baby_in_guest_room.svg";
      else if (this.activity.type === "baby_in_nursery")
        return "/assets/baby_in_nursery.svg";
      else if (this.activity.type === "baby_feeding")
        return "/assets/baby_bottle.svg";
      else if (this.activity.type === "baby_bottle_prep")
        return "/assets/baby_bottle_prep.svg";
      else if (this.activity.type === "breastmilk_received")
        return "/assets/breastmilk_received.svg";
      else if (this.activity.type === "baby_bath")
        return "/assets/baby_bath.svg";
      else if (this.activity.type === "baby_diaper_wet")
        return "/assets/baby_diaper_wet.svg";
      else if (this.activity.type === "baby_diaper_poop")
        return "/assets/baby_diaper_poop.svg";
      else if (this.activity.type === "baby_diaper_mixed")
        return "/assets/baby_diaper_mixed.svg";
      else if (this.activity.type === "baby_diaper_empty")
        return "/assets/baby_diaper_empty.svg";
      else if (this.activity.type === "sales_info_session_zoom")
        return "/assets/sales_info_session.svg";
      else if (this.activity.type === "sales_ca_call_zoom")
        return "/assets/sales_ca_call.svg";
      else if (this.activity.type === "sales_tour_onsite")
        return "/assets/sales_tour.svg";
      else if (this.activity.type === "sales_followup")
        return "/assets/sales_followup.svg";
      else if (this.activity.type === "sales_reservation")
        return "/assets/sales_reservation.svg";
      else return "/assets/question_mark.svg";
    },

    activityTitle() {
      if (this.activity.type === "note")
        return "Team Note";
      if (this.activity.type === "cc_note")
        return "Boram Anywhere Note";
      else if (this.activity.type === "sitz_bath")
        return "Sitz Bath";
      else if (this.activity.type === "foot_bath")
        return "Foot Bath";
      else if (this.activity.type === "breast_massage")
        return "Breast Massage";
      else if (this.activity.type === "lactation_assistance")
        return "Lactation Assistance";
      else if (this.activity.type === "pump_delivered")
        return "Breast Pump Delivered";
      else if (this.activity.type === "pump_retrieved")
        return "Breast Pump Retrieved";
      else if (this.activity.type === "baby_in_guest_room")
        return "Baby in Guest Room";
      else if (this.activity.type === "baby_in_nursery")
        return "Baby in Nursery";
      else if (this.activity.type === "baby_feeding")
        return "Baby Feeding";
      else if (this.activity.type === "baby_bottle_prep")
        return "Bottle Prepared";
      else if (this.activity.type === "breastmilk_received")
        return "Collected Breastmilk";
      else if (this.activity.type === "baby_bath")
        return "Baby Bath";
      else if (this.activity.type === "baby_diaper_wet")
        return "Diaper (Wet)";
      else if (this.activity.type === "baby_diaper_poop")
        return "Diaper (Poop)";
      else if (this.activity.type === "baby_diaper_mixed")
        return "Diaper (Mixed)";
      else if (this.activity.type === "baby_diaper_empty")
        return "Diaper (Empty)";
      else if (this.activity.type === "sales_info_session_zoom")
        return "Info Session (Zoom)";
      else if (this.activity.type === "sales_ca_call_zoom")
        return "1:1 CA Call (Zoom or Phone)";
      else if (this.activity.type === "sales_tour_onsite")
        return "Tour (Onsite)";
      else if (this.activity.type === "sales_followup")
        return "Sales Followup (Automated)";
      else if (this.activity.type === "sales_reservation")
        return "Reservation Made!";
      else return "Loading...";
    },

    showMeasurement1() {
      if (this.activity.type === "baby_feeding" || this.activity.type === "breastmilk_received")
        return true;
      return false;
    },
  },

  data() {
    return {
      formatTimeAndDate,
    };
  },
};
</script>

<style scoped>
ion-item {
  --padding-bottom: 8px;
  --padding-top: 8px;
  --border-color: var(--light-line-color);
}

.ion-text-wrap {
  font-size: 16px;
}
</style>